<template lang="pug">
.row
  .col-lg-3.col-12
    .product-sidebar.d-lg-block(:class="{'d-none': !sidebar}")
      .single-widget.condition(v-if="collections != '[]'")
        h3 Collections
        ul.list
          li(v-for="(e) in JSON.parse(collections)")
            input.form-check-input(type="checkbox" :id="`colle-${e[0]}`" :value="e[0]" v-model="selectedCollections")
            label.form-check-label(:for="`colle-${e[0]}`" style="margin-left: 5px;"): strong {{e[1]}}
      .single-widget.condition(v-if="marketing_events != '[]'")
        h3 Aktion
        ul.list
          li(v-for="(e) in JSON.parse(marketing_events)")
            input.form-check-input(type="checkbox" :id="`me-${e[0]}`" :value="e[0]" v-model="selectedMarketingEvents")
            label.form-check-label(:for="`me-${e[0]}`" style="margin-left: 5px;"): strong {{e[1]}}
      .single-widget.condition(v-if="categories && categories.length != 0")
        h3 Kategorien
        ul.list
          li(v-for="c in categories"): div(v-if="c.count != 0 || origin == 0")
            input.form-check-input(type="checkbox" :id="`c-${c.id}`" :value="c.id" v-model="selectedMainCategories")
            label.form-check-label(:for="`c-${c.id}`" style="margin-left: 5px;"): strong {{c.name}}
            ul.list(v-if="selectedMainCategories.indexOf(c.id) != -1" style="margin-left: 15px;")
              li(v-for="cc in c.children"): div(v-if="cc.count != 0 || origin == 0")
                input.form-check-input(type="checkbox" :id="`cc-${cc.id}`" :value="cc.id" v-model="selectedCategories")
                label.form-check-labelel(:for="`cc-${cc.id}`" style="margin-left: 5px;"): strong {{cc.name}}
      .single-widget.range(v-if="price")
        h3 Preis
        input.form-range(type="range" name="range" step="0.01" min="0" :max="maxPrice" v-model="price" @change="(x) => _price = price")
        .range-inner
          input#rangePrimary(type="text" @change="(x) => price = currencyToNumber(x)" :value="numberToCurrency(price)")
          label 0,00 € - 

      .single-widget.condition(v-if="merchants.length != 0")
        h3 Händler
        .form-check(v-for="merchant in merchants")
          input.form-check-input(:id="`m-${merchant.id}`" type="checkbox" :value="merchant.id" v-model="selectedMerchants")
          label(:for="`m-${merchant.id}`") {{merchant.name}}
      .single-widget.condition(v-if="isRegion")
        h3 Ort
        .form-check
          input.form-check-input#region-f(type="checkbox" value="1" v-model="region")
          label(for="region-f") Produkte aus Deiner Region
        .form-check
          input.form-check-input#federal_state(type="checkbox" value="1" v-model="federal_state")
          label(for="federal_state") Produkte aus Deinem Bundesland
        .form-check
          input.form-check-input#country(type="checkbox" value="1" v-model="country")
          label(for="country") Produkte aus Deinem Land

      .single-widget.condition
        h3 Bewertung
        .give-review: ul
          li
            input.form-check-input(type="checkbox" id="r-5" value="5" v-model="selectedRatings")
            label.form-check-label(for="r-5" style="margin-left: 15px;")
              span 5 Sterne
              svg(xmlns="http://www.w3.org/2000/svg", style="width: 16px; height: 16px;", viewBox="0 0 24 24")
                path(fill="currentColor", d="M12,17.27L18.18,21L16.54,13.97L22,9.24L14.81,8.62L12,2L9.19,8.62L2,9.24L7.45,13.97L5.82,21L12,17.27Z")
              svg(xmlns="http://www.w3.org/2000/svg", style="width: 16px; height: 16px;", viewBox="0 0 24 24")
                path(fill="currentColor", d="M12,17.27L18.18,21L16.54,13.97L22,9.24L14.81,8.62L12,2L9.19,8.62L2,9.24L7.45,13.97L5.82,21L12,17.27Z")
              svg(xmlns="http://www.w3.org/2000/svg", style="width: 16px; height: 16px;", viewBox="0 0 24 24")
                path(fill="currentColor", d="M12,17.27L18.18,21L16.54,13.97L22,9.24L14.81,8.62L12,2L9.19,8.62L2,9.24L7.45,13.97L5.82,21L12,17.27Z")
              svg(xmlns="http://www.w3.org/2000/svg", style="width: 16px; height: 16px;", viewBox="0 0 24 24")
                path(fill="currentColor", d="M12,17.27L18.18,21L16.54,13.97L22,9.24L14.81,8.62L12,2L9.19,8.62L2,9.24L7.45,13.97L5.82,21L12,17.27Z")
              svg(xmlns="http://www.w3.org/2000/svg", style="width: 16px; height: 16px;", viewBox="0 0 24 24")
                path(fill="currentColor", d="M12,17.27L18.18,21L16.54,13.97L22,9.24L14.81,8.62L12,2L9.19,8.62L2,9.24L7.45,13.97L5.82,21L12,17.27Z")
          li
            input.form-check-input(type="checkbox" id="r-4" value="4" v-model="selectedRatings")
            label.form-check-label(for="r-4" style="margin-left: 15px;")
              span 4 Sterne
              svg(xmlns="http://www.w3.org/2000/svg", style="width: 16px; height: 16px;", viewBox="0 0 24 24")
                path(fill="currentColor", d="M12,17.27L18.18,21L16.54,13.97L22,9.24L14.81,8.62L12,2L9.19,8.62L2,9.24L7.45,13.97L5.82,21L12,17.27Z")
              svg(xmlns="http://www.w3.org/2000/svg", style="width: 16px; height: 16px;", viewBox="0 0 24 24")
                path(fill="currentColor", d="M12,17.27L18.18,21L16.54,13.97L22,9.24L14.81,8.62L12,2L9.19,8.62L2,9.24L7.45,13.97L5.82,21L12,17.27Z")
              svg(xmlns="http://www.w3.org/2000/svg", style="width: 16px; height: 16px;", viewBox="0 0 24 24")
                path(fill="currentColor", d="M12,17.27L18.18,21L16.54,13.97L22,9.24L14.81,8.62L12,2L9.19,8.62L2,9.24L7.45,13.97L5.82,21L12,17.27Z")
              svg(xmlns="http://www.w3.org/2000/svg", style="width: 16px; height: 16px;", viewBox="0 0 24 24")
                path(fill="currentColor", d="M12,17.27L18.18,21L16.54,13.97L22,9.24L14.81,8.62L12,2L9.19,8.62L2,9.24L7.45,13.97L5.82,21L12,17.27Z")
              svg(xmlns="http://www.w3.org/2000/svg", style="width: 16px; height: 16px;", viewBox="0 0 24 24")
                path(fill="currentColor", d="M12,15.39L8.24,17.66L9.23,13.38L5.91,10.5L10.29,10.13L12,6.09L13.71,10.13L18.09,10.5L14.77,13.38L15.76,17.66M22,9.24L14.81,8.63L12,2L9.19,8.63L2,9.24L7.45,13.97L5.82,21L12,17.27L18.18,21L16.54,13.97L22,9.24Z")
          li
            input.form-check-input(type="checkbox" id="r-3" value="3" v-model="selectedRatings")
            label.form-check-label(for="r-3" style="margin-left: 15px;")
              span 3 Sterne
              svg(xmlns="http://www.w3.org/2000/svg", style="width: 16px; height: 16px;", viewBox="0 0 24 24")
                path(fill="currentColor", d="M12,17.27L18.18,21L16.54,13.97L22,9.24L14.81,8.62L12,2L9.19,8.62L2,9.24L7.45,13.97L5.82,21L12,17.27Z")
              svg(xmlns="http://www.w3.org/2000/svg", style="width: 16px; height: 16px;", viewBox="0 0 24 24")
                path(fill="currentColor", d="M12,17.27L18.18,21L16.54,13.97L22,9.24L14.81,8.62L12,2L9.19,8.62L2,9.24L7.45,13.97L5.82,21L12,17.27Z")
              svg(xmlns="http://www.w3.org/2000/svg", style="width: 16px; height: 16px;", viewBox="0 0 24 24")
                path(fill="currentColor", d="M12,17.27L18.18,21L16.54,13.97L22,9.24L14.81,8.62L12,2L9.19,8.62L2,9.24L7.45,13.97L5.82,21L12,17.27Z")
              svg(xmlns="http://www.w3.org/2000/svg", style="width: 16px; height: 16px;", viewBox="0 0 24 24")
                path(fill="currentColor", d="M12,15.39L8.24,17.66L9.23,13.38L5.91,10.5L10.29,10.13L12,6.09L13.71,10.13L18.09,10.5L14.77,13.38L15.76,17.66M22,9.24L14.81,8.63L12,2L9.19,8.63L2,9.24L7.45,13.97L5.82,21L12,17.27L18.18,21L16.54,13.97L22,9.24Z")
              svg(xmlns="http://www.w3.org/2000/svg", style="width: 16px; height: 16px;", viewBox="0 0 24 24")
                path(fill="currentColor", d="M12,15.39L8.24,17.66L9.23,13.38L5.91,10.5L10.29,10.13L12,6.09L13.71,10.13L18.09,10.5L14.77,13.38L15.76,17.66M22,9.24L14.81,8.63L12,2L9.19,8.63L2,9.24L7.45,13.97L5.82,21L12,17.27L18.18,21L16.54,13.97L22,9.24Z")
          li
            input.form-check-input(type="checkbox" id="r-2" value="2" v-model="selectedRatings")
            label.form-check-label(for="r-2" style="margin-left: 15px;")
              span 2 Sterne
              svg(xmlns="http://www.w3.org/2000/svg", style="width: 16px; height: 16px;", viewBox="0 0 24 24")
                path(fill="currentColor", d="M12,17.27L18.18,21L16.54,13.97L22,9.24L14.81,8.62L12,2L9.19,8.62L2,9.24L7.45,13.97L5.82,21L12,17.27Z")
              svg(xmlns="http://www.w3.org/2000/svg", style="width: 16px; height: 16px;", viewBox="0 0 24 24")
                path(fill="currentColor", d="M12,17.27L18.18,21L16.54,13.97L22,9.24L14.81,8.62L12,2L9.19,8.62L2,9.24L7.45,13.97L5.82,21L12,17.27Z")
              svg(xmlns="http://www.w3.org/2000/svg", style="width: 16px; height: 16px;", viewBox="0 0 24 24")
                path(fill="currentColor", d="M12,15.39L8.24,17.66L9.23,13.38L5.91,10.5L10.29,10.13L12,6.09L13.71,10.13L18.09,10.5L14.77,13.38L15.76,17.66M22,9.24L14.81,8.63L12,2L9.19,8.63L2,9.24L7.45,13.97L5.82,21L12,17.27L18.18,21L16.54,13.97L22,9.24Z")
              svg(xmlns="http://www.w3.org/2000/svg", style="width: 16px; height: 16px;", viewBox="0 0 24 24")
                path(fill="currentColor", d="M12,15.39L8.24,17.66L9.23,13.38L5.91,10.5L10.29,10.13L12,6.09L13.71,10.13L18.09,10.5L14.77,13.38L15.76,17.66M22,9.24L14.81,8.63L12,2L9.19,8.63L2,9.24L7.45,13.97L5.82,21L12,17.27L18.18,21L16.54,13.97L22,9.24Z")
              svg(xmlns="http://www.w3.org/2000/svg", style="width: 16px; height: 16px;", viewBox="0 0 24 24")
                path(fill="currentColor", d="M12,15.39L8.24,17.66L9.23,13.38L5.91,10.5L10.29,10.13L12,6.09L13.71,10.13L18.09,10.5L14.77,13.38L15.76,17.66M22,9.24L14.81,8.63L12,2L9.19,8.63L2,9.24L7.45,13.97L5.82,21L12,17.27L18.18,21L16.54,13.97L22,9.24Z")
          li
            input.form-check-input(type="checkbox" id="r-1" value="1" v-model="selectedRatings")
            label.form-check-label(for="r-1" style="margin-left: 15px;")
              span 1 Stern
              svg(xmlns="http://www.w3.org/2000/svg", style="width: 16px; height: 16px;", viewBox="0 0 24 24")
                path(fill="currentColor", d="M12,17.27L18.18,21L16.54,13.97L22,9.24L14.81,8.62L12,2L9.19,8.62L2,9.24L7.45,13.97L5.82,21L12,17.27Z")
              svg(xmlns="http://www.w3.org/2000/svg", style="width: 16px; height: 16px;", viewBox="0 0 24 24")
                path(fill="currentColor", d="M12,15.39L8.24,17.66L9.23,13.38L5.91,10.5L10.29,10.13L12,6.09L13.71,10.13L18.09,10.5L14.77,13.38L15.76,17.66M22,9.24L14.81,8.63L12,2L9.19,8.63L2,9.24L7.45,13.97L5.82,21L12,17.27L18.18,21L16.54,13.97L22,9.24Z")
              svg(xmlns="http://www.w3.org/2000/svg", style="width: 16px; height: 16px;", viewBox="0 0 24 24")
                path(fill="currentColor", d="M12,15.39L8.24,17.66L9.23,13.38L5.91,10.5L10.29,10.13L12,6.09L13.71,10.13L18.09,10.5L14.77,13.38L15.76,17.66M22,9.24L14.81,8.63L12,2L9.19,8.63L2,9.24L7.45,13.97L5.82,21L12,17.27L18.18,21L16.54,13.97L22,9.24Z")
              svg(xmlns="http://www.w3.org/2000/svg", style="width: 16px; height: 16px;", viewBox="0 0 24 24")
                path(fill="currentColor", d="M12,15.39L8.24,17.66L9.23,13.38L5.91,10.5L10.29,10.13L12,6.09L13.71,10.13L18.09,10.5L14.77,13.38L15.76,17.66M22,9.24L14.81,8.63L12,2L9.19,8.63L2,9.24L7.45,13.97L5.82,21L12,17.27L18.18,21L16.54,13.97L22,9.24Z")
              svg(xmlns="http://www.w3.org/2000/svg", style="width: 16px; height: 16px;", viewBox="0 0 24 24")
                path(fill="currentColor", d="M12,15.39L8.24,17.66L9.23,13.38L5.91,10.5L10.29,10.13L12,6.09L13.71,10.13L18.09,10.5L14.77,13.38L15.76,17.66M22,9.24L14.81,8.63L12,2L9.19,8.63L2,9.24L7.45,13.97L5.82,21L12,17.27L18.18,21L16.54,13.97L22,9.24Z")

      .single-widget.condition
        h3 Produktfilter
        .form-check
          input.form-check-input#stocked(type="checkbox" value="1" v-model="stocked")
          label(for="stocked") Auf Lager
        .form-check
          input.form-check-input#new(type="checkbox" value="1" v-model="new")
          label(for="new") Neu
        .form-check
          input.form-check-input#discounted(type="checkbox" value="1" v-model="discounted")
          label(for="discounted") Rabattiert

      .single-widget.condition(v-if="isLebensmittel")
        h3 Lebensmittel
        .form-check
          input.form-check-input#bio(type="checkbox" value="1" v-model="bio")
          label(for="bio") BIO
        .form-check
          input.form-check-input#vegan(type="checkbox" value="1" v-model="vegan")
          label(for="vegan") Vegan
  .col-lg-9.col-12
    .product-grids-head
      .product-grid-topbar
        .row.align-items-center
          .col-lg-7.col-md-8.col-12
            .product-sorting
              label(for="sorting") Sortieren:
              select#sorting.form-control(v-model="sorting")
                option(value="0") Neuheit
                option(value="1") Rabatt
                option(value="2") Beliebtheit
                option(value="3") Bewertung aufsteigend
                option(value="4") Preis absteigend
                option(value="5") Preis aufsteigend
                option(value="6") Title absteigend
                option(value="7") Title aufsteigend
              h4.total-show-product.d-none.d-md-inline {{count}} Produkte
          .col-lg-5.col-md-4.col-12
            nav
              .nav.nav-tabs.d-lg-none(role="tablist")
                button.nav-link(type="button" :class="{'active': sidebar}" @click="sidebar = !sidebar")
                  svg(xmlns="http://www.w3.org/2000/svg", viewBox="0 0 24 24" style="width: 24px; height: 24px; margin-right: 10px;"): path(fill="currentColor" d="M22.77 19.32L21.7 18.5C21.72 18.33 21.74 18.17 21.74 18S21.73 17.67 21.7 17.5L22.76 16.68C22.85 16.6 22.88 16.47 22.82 16.36L21.82 14.63C21.76 14.5 21.63 14.5 21.5 14.5L20.27 15C20 14.82 19.73 14.65 19.42 14.53L19.23 13.21C19.22 13.09 19.11 13 19 13H17C16.87 13 16.76 13.09 16.74 13.21L16.55 14.53C16.25 14.66 15.96 14.82 15.7 15L14.46 14.5C14.35 14.5 14.22 14.5 14.15 14.63L13.15 16.36C13.09 16.47 13.11 16.6 13.21 16.68L14.27 17.5C14.25 17.67 14.24 17.83 14.24 18S14.25 18.33 14.27 18.5L13.21 19.32C13.12 19.4 13.09 19.53 13.15 19.64L14.15 21.37C14.21 21.5 14.34 21.5 14.46 21.5L15.7 21C15.96 21.18 16.24 21.35 16.55 21.47L16.74 22.79C16.76 22.91 16.86 23 17 23H19C19.11 23 19.22 22.91 19.24 22.79L19.43 21.47C19.73 21.34 20 21.18 20.27 21L21.5 21.5C21.63 21.5 21.76 21.5 21.83 21.37L22.83 19.64C22.89 19.53 22.86 19.4 22.77 19.32M18 19.5C17.16 19.5 16.5 18.83 16.5 18S17.17 16.5 18 16.5 19.5 17.17 19.5 18 18.83 19.5 18 19.5M17.62 3.22C17.43 3.08 17.22 3 17 3H3C2.78 3 2.57 3.08 2.38 3.22C1.95 3.56 1.87 4.19 2.21 4.62L7 10.75V15.87C6.96 16.16 7.06 16.47 7.29 16.7L11.3 20.71C11.4 20.81 11.5 20.88 11.65 20.93C11.22 20 11 19 11 18C11 16.17 11.72 14.41 13 13.1V10.75L17.79 4.62C18.13 4.19 18.05 3.56 17.62 3.22M11 10.05V17.58L9 15.58V10.06L5.04 5H14.96L11 10.05Z")
                  | Filter anzeigen
              #nav-tab.nav.nav-tabs.d-none.d-lg-flex(role="tablist")
                button#nav-grid-tab.nav-link.active(data-bs-toggle="tab" data-bs-target="#nav-grid" type="button" role="tab" aria-controls="nav-grid" aria-selected="true")
                  svg(xmlns="http://www.w3.org/2000/svg", viewBox="0 0 24 24" style="width: 24px; height: 24px;"): path(fill="currentColor" d="M3,11H11V3H3M3,21H11V13H3M13,21H21V13H13M13,3V11H21V3")
                button#nav-list-tab.nav-link(data-bs-toggle="tab" data-bs-target="#nav-list" type="button" role="tab" aria-controls="nav-list" aria-selected="false")
                  svg(xmlns="http://www.w3.org/2000/svg", viewBox="0 0 24 24" style="width: 24px; height: 24px;"): path(fill="currentColor" d="M3,4H7V8H3V4M9,5V7H21V5H9M3,10H7V14H3V10M9,11V13H21V11H9M3,16H7V20H3V16M9,17V19H21V17H9")
      .alert.alert-info.my-4(v-if="products.length === 0 && !skipFetch && !loading") Leider wurden keine Produkte gefunden.
      #nav-tabContent.tab-content
        #nav-grid.tab-pane.fade.active.show(role="tabpanel" aria-labelledby="nav-grid-tab")
          .row
            .col-lg-4.col-md-6.col-12(v-for="product in products")
              .single-product
                .product-image
                  a.image-container(:href="region_path + product.path"): img(:src="product.img_1")
                  span.sale-tag(v-if="product.discount") -{{product.discount}}%
                  span.new-tag(v-else-if="product.new") NEU
                  span.bio-tag(v-else-if="product.new") BIO
                  span.vegan-tag(v-else-if="product.vegan") VEGAN

                  .button.d-none.d-lg-block(v-if="product.can_buy")
                    form(method="post" :action="region_path + product.card_path")
                      input(type="hidden" :value="authenticity_token" name="authenticity_token")
                      input(type="hidden" value="1" name="quantity")
                      button.btn(type="submit") In den Einkaufswagen
                .product-info: div
                  span.category {{product.main_category.name}} > {{product.category.name}}
                  h4.title: a(:href="region_path + product.path") {{product.title}}
                  div(v-html="product.rating_html")
                  .price
                    span {{numberToCurrency(product.price)}}
                    span.discount-price(v-if="product.original_price") {{numberToCurrency(product.original_price)}}
          .row
            .col-12
              .pagination.center
                paginate(
                  :page-count="pages"
                  :page-range="3"
                  :margin-pages="2"
                  :click-handler="paginate"
                  prev-text="< Zurück"
                  next-text="Weiter >"
                  container-class="pagination-list"
                  :hide-prev-next="true"
                  v-if="products.length != 0"
                )
        #nav-list.tab-pane.fade(role="tabpanel" aria-labelledby="nav-list-tab")
          .row
            .col-lg-12.col-md-12.col-12(v-for="product in products")
              .single-product
                .row.align-items-center
                  .col-lg-4.col-md-4.col-12
                    .product-image
                      a.image-container(:href="region_path + product.path"): img(:src="product.img_1")
                      span.sale-tag(v-if="product.discount") -{{product.discount}}%
                      span.new-tag(v-else-if="product.new") NEU
                      span.vegan-tag(v-else-if="product.vegan") VEGAN
                      span.bio-tag(v-else-if="product.bio") BIO
                      .button.d-none.d-lg-block(v-if="product.can_buy")
                        form(method="post" :action="region_path + product.card_path")
                          input(type="hidden" :value="authenticity_token" name="authenticity_token")
                          input(type="hidden" value="1" name="quantity")
                          button.btn(type="submit") In den Einkaufswagen

                  .col-lg-8.col-md-8.col-12
                    .product-info: div
                      span.category {{product.main_category.name}} > {{product.category.name}}
                      h4.title: a(:href="region_path + product.path") {{product.title}}
                      div(v-html="product.rating_html")
                      .price
                        span {{numberToCurrency(product.price)}}
                        span.discount-price(v-if="product.original_price") {{numberToCurrency(product.original_price)}}
          .row
            .col-12
              .pagination.center
                paginate(
                  :page-count="pages"
                  :page-range="3"
                  :margin-pages="2"
                  :click-handler="paginate"
                  prev-text="< Zurück"
                  next-text="Weiter >"
                  container-class="pagination-list"
                  :hide-prev-next="true"
                  v-if="products.length != 0"
                )

</template>

<script>
import Paginate from 'vuejs-paginate-next';
export default {
  components: {
    Paginate
  },
  props: [
    'category',
    'marketing_events',
    'collections',
    '_categories',
    '_merchants',
    '_origin',
    'params',
    'authenticity_token',
    'region_path',
    'merchant'
  ],
  data () {
    return {
      count: 0,
      sorting: 0,
      _price: null,
      price: null,
      maxPrice: null,
      products: [],
      selectedRatings: [],
      selectedMerchants: [],
      selectedMainCategories: [],
      selectedCategories: [],
      selectedMarketingEvents: [],
      selectedCollections: [],
      stocked: null,
      new: null,
      discounted: null,
      bio: null,
      vegan: null,
      region: null,
      federal_state: null,
      country: null,
      page: 1,
      pages: 1,
      skipFetch: true,
      origin: null,
      loading: true,
      sidebar: false,
      discount: null
    }
  },
  watch: {
    selectedMainCategories: {
      handler(newVal) {
        this.selectedCategories = this.selectedCategories.filter( c => {
          return this.selectedMainCategories.map(x => this.categories.find(y => y.id == x).children.map(x => x.id)).flat().indexOf(c) != -1
        })
        // this.fetch()
      },
      deep: true
    },
    selectedCollections: {
      handler(newVal) {
        this.fetch()
      },
      deep: true
    }, 
    selectedMarketingEvents: {
      handler(newVal) {
        this.fetch()
      },
      deep: true
    },
    selectedCategories: {
      handler(newVal) {
        this.fetch()
      },
      deep: true
    },
    selectedMerchants: {
      handler(newVal) {
        this.fetch()
      },
      deep: true
    },
    selectedRatings: {
      handler(newVal) {
        this.fetch()
      },
      deep: true
    },
    stocked(){
      this.fetch()
    },
    new(){
      this.fetch()
    },
    discounted(){
      this.fetch()
    },
    bio(){
      this.fetch()
    },
    vegan(){
      this.fetch()
    },
    federal_state(){
      this.fetch()
    },
    region(){
      this.fetch()
    },
    country(){
      this.fetch()
    },
    _price(){
      this.fetch()
    },
    sorting(){
      this.fetch()
    }
  },
  mounted () {
    if (this.category){
      const mainCategory = this.categories.find(c => c.id == this.category)
      if (mainCategory){
        this.selectedMainCategories = [parseInt(this.category)]
      } else {
      const mainCategory = this.categories.find(c => {
        return c.children.find(cc => cc.id == this.category)
      })
      this.selectedMainCategories = [mainCategory.id]
      this.selectedCategories = [parseInt(this.category)]

      }
    }

    const params = JSON.parse(this.params)
    const keys = {
      origin: ["string"],
      collections: ["ints", "selectedCollections"],
      marketing_events: ["ints", "selectedMarketingEvents"],
      main_categories: ["ints", "selectedMainCategories"],
      categories: ["ints", "selectedCategories"],
      merchants: ["ints", "selectedMerchants"],
      ratings: ["ints", "selectedRatings"],
      stocked: ["string"],
      new: ["string"],
      discounted: ["string"],
      bio: ["string"],
      vegan: ["string"],
      price: ["float", "_price"],
      q: ["string"],
      sorting: ["int"],
      page: ["int"],
      region: ["region"],
      federal_state: ["federal_state"],
      country: ["country"],
      discount: ["int"]
    }
    Object.entries(keys).forEach( ([x, [type, kk]]) => {
      if (params[x] && params[x] != "null"){
        if (type === "int"){
          this[kk || x] = parseInt(params[x])
        }else if (type === "float"){
          this[kk || x] = parseFloat(params[x])

        }else if (type === "ints"){
          this[kk || x] = params[x].split(",").map(x => parseInt(x))
        }else{
          this[kk || x] = params[x]
        }
      }
    })

    this.origin = this._origin
    this.price = this._price
    
    setTimeout(() => {
      this.skipFetch = false
      this.fetch(true)
     }, 100)

  },
  computed: {
    isRegion(){
      return this.origin && this.origin.startsWith("r-") && this.origin.length >= 3
    },
    categories(){
      return JSON.parse(this._categories)
    },
    merchants(){
      return JSON.parse(this._merchants)
    },
    isLebensmittel(){
      const mainCategory = this.categories.find(c => c.name === "Lebensmittel")
      const isLebensmittel = mainCategory && mainCategory.count != 0 && (this.selectedMainCategories.length === 0 || this.selectedMainCategories.indexOf(mainCategory.id) != -1)

      if (!isLebensmittel){
        this.bio = null
        this.vegan = null
      }

      return isLebensmittel
    }
  },
  methods: {
    paginate(page){
      this.page = page
      this.fetch()
      window.scrollTo({ top: 0, behavior: 'smooth' });
    },
    currencyToNumber(x){
      this._price = parseFloat(x.target.value)
      return parseFloat(x.target.value)
    },
    numberToCurrency(x){
      return Intl.NumberFormat('de-DE', { style: 'currency', currency: 'EUR' }).format(x)
    },
    fetch(init){
      if (this.skipFetch) return false
    
      this.loading = true

      const selectedMainCategories = this.selectedMainCategories.filter( c => {
        return this.categories.find(y => y.id == c).children.filter(yy => this.selectedCategories.indexOf(yy.id) != -1).length === 0
      })

      let params = {
        merchant: this.merchant,
        origin: this.origin,
        marketing_events: this.selectedMarketingEvents,
        collections: this.selectedCollections,
        main_categories: selectedMainCategories,
        categories: this.selectedCategories,
        merchants: this.selectedMerchants,
        ratings: this.selectedRatings,
        stocked: this.stocked,
        new: this.new,
        discounted: this.discounted,
        bio: this.bio,
        vegan: this.vegan,
        price: this._price,
        q: this.q,
        sorting: this.sorting,
        page: this.page,
        region: this.region,
        federal_state: this.federal_state,
        country: this.country,
        discount: this.discount
      }

      params = Object.fromEntries(Object.entries(params).filter(([_, v]) => v))

      fetch(`/api/products.json?${new URLSearchParams(params)}`)     
        .then((response) => response.json())
        .then((data) => {
          this.products = data.products
          this.pages = data.pages
          this.count = data.count
          if (init){
            this.maxPrice = data.price_max
            this.price = this.price || data.price_max
          }
          this.loading = false
        });

      if (this.selectedMainCategories){
        params.main_categories = this.selectedMainCategories
        window.Turbo.navigator.history.replace(new URL(location.protocol + '//' + location.host + location.pathname+`?${new URLSearchParams(params)}`))
        // window.Turbo.navigator.history.replace(null, null, );
      }
    }
  },
}
</script>

<style scoped lang="scss">
</style>