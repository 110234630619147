import { createApp } from 'vue'
import App from './App.vue'

document.addEventListener("turbo:load", () => {

  const mountEl = document.querySelector("#app");
  if (mountEl){
    const app = createApp(App, { ...mountEl.dataset })
    app.mount('#app')
  }
})

